import React from 'react';
import { Link } from 'react-router-dom';
import { List, ListItem, ListItemIcon, Box } from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';
import SettingsIcon from '@mui/icons-material/Settings';
import LogoIcon from '@mui/icons-material/AcUnit';
import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined'; // Import the icon for SuccessPage
import paynearbyLogo from '../../Assets/PayNearby.png'



const Sidebar: React.FC = () => {
  const nbt_user=sessionStorage.getItem("nbt_user")
  return (
    <Box
      sx={{
        width: '60px', // Narrow width
        backgroundColor: '#ffffff', // White background
        borderRight: '1px solid #ccc', // Border on the right
        height: '100vh', // Full height
        position: 'fixed',
        top: 0,
        left: 0,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <Box
        sx={{
          marginBottom: '-19px', 
          marginTop:'30px', // Space between logo and first item
        }}
      >
        <img 
        src={paynearbyLogo} alt="Logo" style={{ width: '49.53px', height: '49.43px',top:'42.7',left:'33.38px' }} />
      </Box>
      <List sx={{ flex: 1, justifyContent: 'center',marginLeft:'30px',marginTop:'70px' }}>
        <ListItem
          button
          component={Link}
          to={`/${nbt_user}/success`} // Link to the SuccessPage
          sx={{
            justifyContent: 'center',
            marginBottom: '10px', // Space between buttons
            //border: '1px solid #ccc', // Add border to button
            //borderRadius: '5px', // Rounded corners for button
            width: '100%', // Full width button
            alignItems: 'center',
            padding: '5px',
          }}
        >
          <ListItemIcon>
            <DashboardOutlinedIcon />
          </ListItemIcon>
        </ListItem>
        {/* <ListItem
          button
          component={Link}
          to={`/${nbt_user}/settings`}
          sx={{
            justifyContent: 'center',
            marginBottom: '10px',
           // border: '1px solid #ccc',
           // borderRadius: '5px',
            width: '100%',
            alignItems: 'center',
           padding: '5px',
          }}
        >
          <ListItemIcon>
            <SettingsIcon />
          </ListItemIcon>
        </ListItem> */}
      </List>
    </Box>
  );
};

export default Sidebar;
