import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Logo from '../components/assets/logo.png';
import axios from 'axios';
import { Oval } from 'react-loader-spinner'; // Import the loader

const LoginForm: React.FC = () => {
  const [user_name, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const navigate = useNavigate();

  const nbt_user = sessionStorage.getItem("nbt_user");

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true);
    setError(null);

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/${process.env.REACT_APP_BACKEND_ENV}/auth/admin/${nbt_user}/login`,
        {
          user_name,
          password,
        },
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );

      const { access_token } = response.data;
      if (access_token) {
        sessionStorage.setItem('authToken', access_token);
        navigate(`/${nbt_user}/success`);
      } else {
        setError('User credentials aren’t correct.');
      }
    } catch (error) {
      console.error('Error:', error);
      setError('An error occurred while logging in. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div
      style={{
        backgroundColor: '#BDC6E7',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        margin: 0,
      }}
    >
      <div style={{ textAlign: 'center' }}>
        <img
          src={Logo}
          alt="Logo"
          style={{
            width: '100px',
            display: 'block',
            margin: '0 auto 20px',
            height: '40px',
          }}
        />
        <h1 style={{ marginLeft: '50px', marginBottom: '30px', fontWeight: 'normal', fontSize: '24px' }}>
          <strong>Open Finance</strong> Admin Dashboard
        </h1>
        <div
          style={{
            backgroundColor: 'white',
            padding: '20px 40px',
            borderRadius: '8px',
            boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
            textAlign: 'center',
          }}
        >
          <h1 style={{ fontWeight: 'normal', fontSize: '24px' }}>
            Admin <strong>Login</strong>
          </h1>
          <form
            onSubmit={handleSubmit}
            style={{
              display: 'flex',
              flexDirection: 'column',
              gap: '30px',
              alignItems: 'center',
              width: '500px',
              height: '300px',
            }}
          >
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
              <label htmlFor="username" style={{ marginBottom: '5px' }}>Username:</label>
              <input
                type="text"
                id="username"
                value={user_name}
                onChange={(e) => setUsername(e.target.value)}
                required
                style={{
                  padding: '10px',
                  border: '1px solid #ccc',
                  borderRadius: '4px',
                  width: '300px',
                  maxWidth: '300px',
                }}
              />
            </div>
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
              <label htmlFor="password" style={{ marginBottom: '5px' }}>Password:</label>
              <input
                type="password"
                id="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
                style={{
                  padding: '10px',
                  border: '1px solid #ccc',
                  borderRadius: '4px',
                  width: '300px',
                  maxWidth: '300px',
                }}
              />
            </div>
            <button
              type="submit"
              style={{
                padding: '10px 20px',
                backgroundColor: '#4A90E2',
                color: 'white',
                border: 'none',
                borderRadius: '4px',
                cursor: 'pointer',
                width: '300px',
              }}
              disabled={loading}
            >
              {loading ? 'Logging in...' : 'Login'}
            </button>
            {loading && (
              <Oval
                height={40}
                width={40}
                color="#4A90E2"
                wrapperStyle={{ margin: '20px auto' }}
                visible={true}
                ariaLabel='oval-loading'
                secondaryColor="#4A90E2"
              />
            )}
            {error && <p style={{ color: 'red' }}>{error}</p>}
          </form>
        </div>
      </div>
    </div>
  );
};

export default LoginForm;
