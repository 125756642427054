// axiosInstance.ts
import axios from "axios";
import { useNavigate } from "react-router-dom";
// import { useTokenHandler } from './tokenHandler';

const axiosInstance = axios.create({
  baseURL:`${process.env.REACT_APP_API_BASE_URL}/${process.env.REACT_APP_BACKEND_ENV}`,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
    "ngrok-skip-browser-warning": "69420",
  },
});

// Add a request interceptor
// axiosInstance.interceptors.request.use(
//   (config) => {
//     const token = sessionStorage.getItem("authToken");
//     if (token) {
//       config.headers.Authorization = `Bearer ${token}`;
//     }
//     return config;
//   },
//   (error) => {
//     return Promise.reject(error);
//   }
// );

// Add a response interceptor
axiosInstance.interceptors.response.use(
  (response) => {
    if (response.status === 200) {
      console.log("Success:", response.data);
    }
    return response;
  },
  (error) => {
    if (error.response) {
      if (error.response.status === 401) {
        console.error("Unauthorized: Token has expired or is invalid.");
        sessionStorage.removeItem("authToken");
        const nbtuser = sessionStorage.getItem("nbt_user");
        window.location.href = `/${nbtuser}`; // Redirect to login
      } else {
        console.error(`Error: ${error.response.status}`);
      }
    }
    return Promise.reject(error);
  }
);
export default axiosInstance;
